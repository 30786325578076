import React from 'react';
import { Wrapper } from './atoms/Wrapper';
import { Quote } from './atoms/Quote';
import { QuoteSymbol } from './atoms/QuoteSymbol';

export interface PayoffProps {
    description: string;
    isPurple?: boolean;
    extraHeightInPixels?: number;
}

export const Payoff: React.FC<PayoffProps> = ({ description, isPurple = false, extraHeightInPixels = 0 }) => (
    <Wrapper extraHeightInPixels={extraHeightInPixels} isPurple={isPurple}>
        <div>
            <Quote>{description}</Quote>
            <QuoteSymbol />
        </div>
    </Wrapper>
);
