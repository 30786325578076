import styled from 'styled-components';
import { fromLarge, fromMedium } from 'styleUtils/mediaQueries';
import { HighlightDesign, DesignAware } from '../types';
import { gridColumn, gridRow } from 'styleUtils/grid';

export const PayoffWrapper = styled.div<DesignAware>`
    z-index: 1;

    ${fromMedium`
        ${({ design }): string => {
            switch (design) {
                case HighlightDesign.PayoffRight:
                    return `
                        ${gridColumn({ start: 2, end: 3 })}
                        ${gridRow({ start: 1 })}
                    `;
                case HighlightDesign.PayoffLeft:
                    return `
                        ${gridColumn({ start: 1, end: 2 })}
                        ${gridRow({ start: 1 })}
                    `;
            }
        }}
    `};

    ${fromLarge`
        ${gridColumn({ start: 1, end: 3 })}
        ${gridRow({ start: 2, end: 4 })}

        ${({ design }): string => {
            switch (design) {
                case HighlightDesign.PayoffRight:
                    return gridColumn({ start: 3, end: 5 });
                case HighlightDesign.OneImage:
                    return gridRow({ start: 2, end: 4 });
                default:
                    break;
            }
        }}
    `};
`;
