import styled from 'styled-components';
import { FontAwesomeFree } from 'styles/fonts';

export const QuoteSymbol = styled.span`
    font-family: '${FontAwesomeFree}';
    display: block;
    font-size: 300px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    opacity: 0.08;
    &:before {
        content: '\f10e';
        transform: rotate(-15deg);
    }
`;
