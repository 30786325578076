import styled from 'styled-components';

import { fromLarge } from 'styleUtils/mediaQueries';
import { WrapperProps } from './WrapperProps';

export const TitleWrapper = styled.div<WrapperProps>`
    ${fromLarge`
      ${({ titleLeft }) => `
        grid-column-start: 1;
        grid-column-end: ${titleLeft ? 2 : 3};
        grid-row-start: 1;
        grid-row-end: 2;
        ${titleLeft ? ` width: max-content;  margin: 0 auto; ` : `padding-left: 100px; margin-bottom: 20px;`}
      `}
    `}
`;
