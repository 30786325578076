import styled from 'styled-components';
import { brokenWhite } from 'styles/colors';
import { werkenBij } from 'config/config.json';

const { itemHeight } = werkenBij.home.highlight;

import { fromLarge, fromMedium } from 'styleUtils/mediaQueries';
import { gridDisplay } from 'styleUtils/grid';

export const Wrapper = styled.div`
    margin-bottom: 1.5rem;
    overflow: hidden;
    padding: 1.5rem;
    background-color: ${brokenWhite};

    ${fromMedium`
        margin-bottom: 0;
    `};

    ${fromLarge`
        margin-bottom: 60px;
        position: relative;
        margin-bottom: 0;
        margin-top: 0;
        border-bottom: none;
        ${gridDisplay()}
        justify-items: center;
        align-items: center;
        height: ${itemHeight};
    `}
`;
