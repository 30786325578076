import React from 'react';
import Link from 'next/link';
import { Button } from 'components/common/Button';

interface Props {
    type?: string;
    readMoreUrl: string;
}
export const ReadMoreButton: React.FC<Props> = ({ readMoreUrl, type = 'blogs' }) => {
    return (
        <Link as={readMoreUrl} href={`/${type}/[slug]`} passHref>
            <Button more tabIndex={0}>
                Lees meer
            </Button>
        </Link>
    );
};
