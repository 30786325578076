import styled from 'styled-components';
import { fromExtraLarge, fromLarge } from 'styleUtils/mediaQueries';
import { white } from 'styles/colors';

export const Quote = styled.h3`
    color: ${white};
    font-size: 1.1em;
    font-weight: normal;
    line-height: 2.2em;
    margin: 0;
    position: relative;
    text-align: justify;
    z-index: 2;

    ${fromLarge`
        line-height: 1.6em;
        font-size: 1.4em;
    `}

    ${fromExtraLarge`
        line-height: 1.6em;
        font-size: 1.6em;
    `}
`;
