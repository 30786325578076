import styled from 'styled-components';
import { Exo } from 'styles/fonts';
import { grey } from 'styles/colors';
import { gridColumn } from 'styleUtils/grid';

export const PersonWrapper = styled.div`
    ${gridColumn({ start: 2 })}
    display: flex;
    align-items: center;
    color: ${grey};
    font-family: ${Exo};
`;
