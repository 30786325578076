import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';
import { gridRow, gridColumn } from 'styleUtils/grid';

export const TextWrapper = styled.div`
    ${gridColumn({ start: 1, end: 3 })}
    ${gridRow({ start: 2 })}
    margin-top: 10px;

    ${fromLarge`
        ${gridColumn({ start: 2 })}
        ${gridRow({ start: 2 })}
        margin-top: 0;
    `}
`;
