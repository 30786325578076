import React from 'react';

import { Container } from './atoms/Container';
import { TitleWrapper } from './atoms/TitleWrapper';
import { Title } from './atoms/Title';
import { DescriptionWrapper } from './atoms/DescriptionWrapper';
import { Description } from './atoms/Description';

interface Props {
    title: string;
    description: string;
    subtitle?: string;
    titleLeft?: boolean;
}
export const TextBreaker: React.FC<Props> = ({ title, description, titleLeft = false }) => (
    <Container>
        <TitleWrapper titleLeft={titleLeft}>
            <Title>{title}</Title>
        </TitleWrapper>
        <DescriptionWrapper titleLeft={titleLeft}>
            <Description>{description}</Description>
        </DescriptionWrapper>
    </Container>
);
