import React from 'react';
import Markdown from 'markdown-to-jsx';
import { WebImage } from 'components/common/WebImage';
import { Avatar } from 'components/common/Avatar/';
import { Description } from './atoms/Description';
import { FormatTeams } from 'components/common/FormatTeams';
import { GridWrapper } from './atoms/GridWrapper';
import { ImageWrapper } from './atoms/ImageWrapper';
import { PersonWrapper } from './atoms/PersonWrapper';
import { Profile as ProfileProps } from 'types/profile';
import { ReadMoreButton } from 'components/common/ReadMoreButton';
import { ReadMoreWrapper } from './atoms/ReadMoreWrapper';
import { TextWrapper } from './atoms/TextWrapper';
import { Title } from './atoms/Title';
import { Wrapper } from './atoms/Wrapper';
import { truncateAndEllipsis } from 'utils/truncate';

export const Profile: React.FC<ProfileProps> = (props) => {
    const { title, leadContent, user, slug } = props;

    return (
        <Wrapper>
            <GridWrapper>
                <ImageWrapper>
                    <Avatar size="75px">
                        <WebImage
                            image={{
                                alt: title,
                                ...user.imageAttachments[0].image,
                            }}
                        />
                    </Avatar>
                </ImageWrapper>
                <PersonWrapper>
                    <div>
                        {user.fullname} - <FormatTeams teams={user.teams} />
                    </div>
                </PersonWrapper>
                <TextWrapper>
                    <div>
                        <Title>{title}</Title>
                    </div>
                    <div>
                        <Description>
                            <p>
                                <Markdown>{truncateAndEllipsis(25, leadContent)}</Markdown>
                            </p>
                        </Description>
                        <ReadMoreWrapper>
                            <ReadMoreButton readMoreUrl={`/blogs/${slug}`} />
                        </ReadMoreWrapper>
                    </div>
                </TextWrapper>
            </GridWrapper>
        </Wrapper>
    );
};
