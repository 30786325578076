import styled from 'styled-components';
import { fromLarge, fromMedium } from 'styleUtils/mediaQueries';
import { HighlightDesign, DesignAware } from '../types';
import { gridDisplay, gridTemplateColumns, gridTemplateRows } from 'styleUtils/grid';

export const HighlightWrapper = styled.div<DesignAware>`
    ${fromMedium`
        ${({ design }): string => {
            switch (design) {
                case HighlightDesign.PayoffRight:
                    return `
                        ${gridDisplay()}
                        ${gridTemplateColumns('40% 60%')}
                        ${gridTemplateRows('auto')}
                    `;
                case HighlightDesign.PayoffLeft:
                    return `
                        ${gridDisplay()}
                        ${gridTemplateColumns('60% 40%')}
                        ${gridTemplateRows('auto')}
                    `;
            }
        }}
    `};

    ${fromLarge`
        ${gridDisplay()}
        ${gridTemplateColumns('25% 25% 25% 25%')}
        ${gridTemplateRows('150px 225px 225px 225px 150px')}
        overflow: hidden;

        ${({ design }): string =>
            (design === HighlightDesign.OnlyPayoff || design === HighlightDesign.OneImage) &&
            gridTemplateRows('125px 150px 150px 125px')}
    `}
`;
