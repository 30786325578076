import styled from 'styled-components';
import { toLarge } from 'styleUtils/mediaQueries';
import { lightText } from 'styles/colors';

export const PersonName = styled.b`
    color: ${lightText};
    display: block;

    ${toLarge`
    font-size: 0.9em;
  `}
`;
