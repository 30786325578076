import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';
import { lightText } from 'styles/colors';
import { gridRow } from 'styleUtils/grid';

export const DescriptionText = styled.div`
    color: ${lightText};
    h1 {
        ${gridRow({ start: 1 })}
        margin: 0 0 16px 0;
        font-weight: 400;
        font-size: 1.6em;

        ${fromLarge`
          font-size: 3em;
        `};
    }
    h2 {
        font-size: 1.2em;
        font-weight: 400;
        margin: 12px 0 22px;

        ${fromLarge`
          font-size: 1.8em;
        `};
    }
    p {
        text-align: justify;
        margin-bottom: 0;
    }
`;
