import styled from 'styled-components';
import { fromLarge, fromMedium } from 'styleUtils/mediaQueries';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    margin: 1.5rem 0;
    ${fromMedium`
        grid-template-columns: 30% 70%;
        grid-template-rows: auto;
        align-items: center;
        margin: 50px 0 50px 0;
    `};
    ${fromLarge`
        grid-template-columns: 30% 70%;
        grid-template-rows: auto;
        margin: 100px 0 100px 0;
        align-items: center;
    `}
`;
