import styled from 'styled-components';

import { headerHeight } from 'components/header/Header/atoms/variables';

import { backgroundImages } from 'polished';
import { gradient } from 'styles/colors';
import { fromLarge, toLarge } from 'styleUtils/mediaQueries';

export const IntroWrapper = styled.div`
    background-position: bottom;
    background-size: cover;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;

    ${backgroundImages(gradient, `url("/images/banner.jpg")`)}
    .webp & {
        ${backgroundImages(gradient, `url("/images/banner.webp")`)}
    }

    ${toLarge`
        justify-content: center
    `};

    ${fromLarge`
        position: relative;
        padding: 100px 0;
        min-height: calc(100vh - ${headerHeight});
    `}
`;
