import React from 'react';
import Markdown from 'markdown-to-jsx';
import { Avatar } from 'components/common/Avatar/';
import { AvatarWrapper } from './atoms/AvatarWrapper';
import { Container } from './atoms/Container';
import { DescriptionText } from './atoms/DescriptionText';
import { FormatTeams } from 'components/common/FormatTeams';
import { IntroText } from './atoms/IntroText';
import { Person } from './atoms/Person';
import { PersonDescriptionText } from './atoms/PersonDescriptionText';
import { PersonDetailsWrapper } from './atoms/PersonDetailsWrapper';
import { PersonName } from './atoms/PersonName';
import { PersonRole } from './atoms/PersonRole';
import { PersonTop } from './atoms/PersonTop';
import { ReadMoreButton } from 'components/common/ReadMoreButton';
import { ReadMoreWrapper } from './atoms/ReadMoreWrapper';
import { TeamUser } from 'types/teamUser';
import { WebImage } from 'components/common/WebImage';
import { truncateAndEllipsis } from 'utils/truncate';

interface Props {
    user: TeamUser;
    description: string;
    personDescription: string;
    id: number;
    slug: string;
}

export const Intro: React.FC<Props> = ({
    user: { fullname, imageAttachments, teams },
    description,
    slug,
    personDescription,
}) => {
    return (
        <Container>
            <IntroText>
                <DescriptionText>
                    <Markdown>{description}</Markdown>
                </DescriptionText>
            </IntroText>
            <Person>
                <PersonTop>
                    <AvatarWrapper>
                        <Avatar size="100px">
                            <WebImage
                                image={{
                                    alt: fullname,
                                    ...imageAttachments[0].image,
                                }}
                            />
                        </Avatar>
                    </AvatarWrapper>
                    <PersonDetailsWrapper>
                        <PersonName>{fullname}</PersonName>
                        <PersonRole>
                            <FormatTeams teams={teams} />
                        </PersonRole>
                    </PersonDetailsWrapper>
                </PersonTop>
                <PersonDescriptionText>
                    <div>
                        <Markdown>{truncateAndEllipsis(45, personDescription)}</Markdown>
                    </div>
                    <ReadMoreWrapper>
                        <ReadMoreButton readMoreUrl={`/blogs/${slug}`} />
                    </ReadMoreWrapper>
                </PersonDescriptionText>
            </Person>
        </Container>
    );
};
