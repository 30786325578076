import React from 'react';
import { Mouse } from './atoms/Mouse';
import { Scroller } from './atoms/Scroller';

export const PageClip: React.FC = () => {
    return (
        <Mouse>
            <Scroller />
        </Mouse>
    );
};
