import styled from 'styled-components';
import { toLarge } from 'styleUtils/mediaQueries';
import { lightText } from 'styles/colors';

export const PersonRole = styled.p`
    display: block;
    color: ${lightText};

    ${toLarge`
    font-size: 0.9em;
  `}
`;
