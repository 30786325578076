import styled from 'styled-components';
import { Image } from './Image';
import { HighlightDesign, ImageProps } from '../types';
import { gridColumn, gridRow } from 'styleUtils/grid';

export const BottomImage = styled(Image)<ImageProps>`
    ${gridColumn({ start: 1, end: 5 })}
    ${gridRow({ start: 4, end: 6 })}

    ${({ design }): string =>
        (design === HighlightDesign.OnlyPayoff || design === HighlightDesign.OneImage) &&
        ` 
      ${gridColumn({ start: 2, end: 5 })}
      ${gridRow({ start: 3, end: 5 })}
    `}
`;
