import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';

export const Mouse = styled.div`
    display: none;
    width: 22px;
    height: 44px;
    border: 2px solid #fff;
    justify-content: center;
    padding-top: 6px;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: border-box;

    ${fromLarge`
        display: flex;
    `};
`;
