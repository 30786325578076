import styled from 'styled-components';
import { fromLarge, fromMedium } from 'styleUtils/mediaQueries';
import { purple } from 'styles/colors';
import { gridRow, gridColumn, gridDisplay, gridTemplateRows } from 'styleUtils/grid';

export const Person = styled.div`
    ${gridDisplay()}
    ${gridTemplateRows('80px auto')}
    
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    background: ${purple};

    ${fromMedium`
        margin-bottom: 3rem;
    `}

    ${fromLarge`
        ${gridRow({ start: 2, end: 4 })}
        ${gridColumn({ start: 5, end: 8 })}
        height: fit-content;
        padding: 40px;
        margin-bottom: 0;
    `}
`;
