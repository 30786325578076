import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';

export const Title = styled.h4`
    margin: 10px 0 15px;
    font-size: 1.3em;

    ${fromLarge`
        font-size: 1.5em;
    `};
`;
