import styled from 'styled-components';
import { position } from 'polished';
import { fromLarge } from 'styleUtils/mediaQueries';

import config from 'config/config.json';
const {
    werkenBij: {
        connectionLines: { XPositionXl },
    },
} = config;
const bottomPosition = '-182px';
export const Wrapper = styled.div`
    display: none;
    ${fromLarge`
    display: block;
    ${position('absolute', null, null, bottomPosition, XPositionXl)}
  `}
    z-index: 2;
`;
