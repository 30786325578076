import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';
import { gridRow, gridColumn } from 'styleUtils/grid';

export const IntroText = styled.div`
    padding: 1.5rem;
    ${fromLarge`
        ${gridRow({ start: 1, end: 3 })}
        ${gridColumn({ start: 1, end: 4 })}
        padding: 3rem 0;
    `}
`;
