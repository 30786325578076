import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';
import { gridDisplay, gridTemplateColumns, gridTemplateRows } from 'styleUtils/grid';

export const Container = styled.div`
    ${fromLarge`
    padding: 0;

    ${gridTemplateColumns('15% 15% 15% 7.5% 17.5% 15% 15%')}
  `}

    ${gridDisplay()}
    ${gridTemplateColumns('100%')}
    ${gridTemplateRows('auto')}
`;
