import styled from 'styled-components';

export const Description = styled.div`
    text-align: justify;
    h1 {
        font-size: 20px;
        color: black;
    }
    p {
        margin: 0 0 20px 0;
    }
`;
