export enum HighlightDesign {
    OnlyPayoff,
    PayoffLeft,
    PayoffRight,
    OneImage,
}

export interface DesignAware {
    design: HighlightDesign;
}

export interface ImageProps extends DesignAware {
    src: string;
    fallback: string;
}
