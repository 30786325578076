import React from 'react';
import api from 'api/home';
import rootActions from 'redux/rootActions';
import { BreakerToPayoff1Line } from 'components/common/connectionLines/BreakerToPayoff1Line';
import { BreakerToPayoff2Line } from 'components/common/connectionLines/BreakerToPayoff2Line';
import { FlexContainer } from 'components/common/FlexContainer';
import { GetInitialProps } from 'types/getInitialProps';
import { Highlight } from 'components/home/Highlight';
import { HomePage } from 'types/homePage';
import { Intro } from 'components/home/Intro';
import { IntroContainer } from 'components/home/Intro/atoms/IntroContainer';
import { IntroToBreakerLine } from 'components/common/connectionLines/IntroToBreakerLine';
import { IntroWrapper } from 'components/home/Intro/atoms/IntroWrapper';
import { Layout } from 'components/common/Layout';
import { LetsMeet } from 'components/lets-meet/LetsMeet';
import { MetaTags } from 'components/meta/MetaTags';
import { PageClip } from 'components/home/PageClip';
import { PageClipWrapper } from 'components/home/PageClip/atoms/Wrapper';
import { TextBreaker } from 'components/common/TextBreaker';
import { TransparentContainer } from 'components/common/TransparentContainer';
import { connect } from 'react-redux';

const Index = (props: HomePage): JSX.Element => {
    const {
        data: {
            headerText,
            headerProfile,
            learnHighlightText,
            learnIntro,
            learnProfile,
            learnTopImage,
            learnBottomImage,
            shareHighlightText,
            shareIntro,
            shareProfile,
            shareTopImage,
            shareBottomImage,
            codeHighlightText,
            codeIntro,
            codeProfile,
            codeTopImage,
            codeBottomImage,
            letsMeetProfile,
        },
        pathName,
    } = props;

    return (
        <Layout pathName={pathName}>
            <MetaTags
                title="Werken bij Developers.nl | Home"
                image="/favicons/logo_180.png"
                description="Onze visie in drie woorden; learn, code en share. Kom jij ook werken bij Developers.nl?"
                keywords="developer, developers, frontend, php, python, java, learn, code, share"
            />

            <IntroWrapper>
                <IntroContainer>
                    <Intro
                        user={headerProfile.user}
                        id={headerProfile.id}
                        slug={headerProfile.slug}
                        description={headerText}
                        personDescription={headerProfile.leadContent}
                    />
                    <PageClipWrapper>
                        <PageClip />
                    </PageClipWrapper>
                    <IntroToBreakerLine />
                </IntroContainer>
            </IntroWrapper>

            <FlexContainer>
                <TransparentContainer>
                    <TextBreaker titleLeft title="Learn." description={learnIntro} />
                    <BreakerToPayoff1Line />
                </TransparentContainer>
            </FlexContainer>

            <FlexContainer>
                <TransparentContainer>
                    <Highlight
                        payoff={{
                            description: learnHighlightText,
                        }}
                        topImage={learnTopImage}
                        bottomImage={learnBottomImage}
                        profile={learnProfile}
                    />
                </TransparentContainer>
            </FlexContainer>

            <FlexContainer>
                <TransparentContainer>
                    <TextBreaker title="Code." description={codeIntro} />
                    <BreakerToPayoff2Line />
                </TransparentContainer>
            </FlexContainer>

            <FlexContainer>
                <TransparentContainer>
                    <Highlight
                        payoff={{
                            description: codeHighlightText,
                        }}
                        topImage={codeTopImage}
                        bottomImage={codeBottomImage}
                        profile={codeProfile}
                        payoffRight
                    />
                </TransparentContainer>
            </FlexContainer>

            <FlexContainer>
                <TransparentContainer>
                    <TextBreaker titleLeft title="Share." description={shareIntro} />
                    <BreakerToPayoff1Line />
                </TransparentContainer>
            </FlexContainer>

            <FlexContainer>
                <TransparentContainer>
                    <Highlight
                        payoff={{
                            description: shareHighlightText,
                        }}
                        topImage={shareTopImage}
                        bottomImage={shareBottomImage}
                        hasOneImage={false}
                        profile={shareProfile}
                    />
                </TransparentContainer>
            </FlexContainer>

            <FlexContainer>
                <TransparentContainer>
                    <LetsMeet profile={letsMeetProfile} contact />
                </TransparentContainer>
            </FlexContainer>
        </Layout>
    );
};

Index.getInitialProps = async (ctx: GetInitialProps): Promise<any> => {
    const { store } = ctx;

    if (store.getState().home.data) {
        return {
            pathName: ctx.pathname,
        };
    }

    return api.data().then(
        (payload) => {
            store.dispatch(rootActions.fetchHomeSuccess(payload));

            return {
                pathName: ctx.pathname,
            };
        },
        (error) => {
            store.dispatch(rootActions.fetchHomeError(error));

            return {
                pathName: ctx.pathname,
                error: error,
            };
        },
    );
};

export default connect((state) => {
    return {
        data: state.home.data,
        error: state.blogs.error,
    };
}, rootActions)(Index);
