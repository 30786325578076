export const truncateAndEllipsis = (wordCap: number, text: string): string => {
    const inputWordsArray = text.split(' ');
    const modifiedWordsArray = inputWordsArray.slice();
    const averageCharsPerWord = 8;
    const maxCharacters = wordCap * averageCharsPerWord;

    let characterCounter = 0;
    let removeIndex = 0;

    for (let i = 0; i < inputWordsArray.length; i++) {
        const wordLength = inputWordsArray[i].length;
        characterCounter += wordLength;

        if (characterCounter > maxCharacters) {
            removeIndex = i;
            break;
        }
    }

    if (removeIndex > 0) {
        modifiedWordsArray.length = removeIndex;
        let newString = modifiedWordsArray.join(' ');
        newString += '...';
        return newString;
    }

    return text;
};
