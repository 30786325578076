import styled from 'styled-components';
import { toLarge } from 'styleUtils/mediaQueries';
import { ImageProps } from '../types';

export const Image = styled.div<ImageProps>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: ${({ fallback }): string => `url(${fallback})`};
    .webp & {
        background-image: ${({ src }): string => `url(${src})`};
    }

    ${toLarge`
        background-position: 88%;
    `};
`;
