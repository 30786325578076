import React from 'react';
import Head from 'next/head';
import { orange } from 'styles/colors';

interface Props {
    title: string;
    image: string;
    description: string;
    keywords: string;
}

export const MetaTags: React.FC<Props> = (props) => (
    <Head>
        <title>{props.title}</title>

        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />

        <meta property="og:title" content={props.title} />
        <meta property="og:image" content="/favicons/meta-picture.jpeg" />
        <meta property="og:description" content={props.description} />
        <meta property="og:type" content="website" />

        <link rel="icon" type="image/png" href="/images/logo_16.png" sizes="16x16" />
        <link rel="icon" type="image/png" href="/images/logo_32.png" sizes="32x32" />
        <link rel="apple-touch-icon" href="/images/logo_180.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/logo_180.png" />
        <link rel="shortcut icon" href="/images/logo_180.png" />
        <meta name="theme-color" content={orange} />
    </Head>
);
