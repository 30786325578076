import styled from 'styled-components';
import { Exo } from 'styles/fonts';

export const PersonDetailsWrapper = styled.div`
    padding-left: 20px;
    display: inline-block;
    position: static;
    font-size: 1.2em;
    margin: 0;
    font-family: ${Exo};
`;
