import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';

export const PageClipWrapper = styled.div`
    ${fromLarge`
        position: absolute;
        width: fit-content;
        bottom: -72px;
        left: 50%;
        transform: translateX(-50%);
    `}
`;
