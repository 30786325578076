import styled from 'styled-components';

import { fromLarge } from 'styleUtils/mediaQueries';

export const Title = styled.h1`
    margin: 0 0 20px;
    line-height: 35px;
    font-size: 2em;

    ${fromLarge`
        font-size: 2.6em;
    `}
`;
