import styled from 'styled-components';

import { fromLarge } from 'styleUtils/mediaQueries';
import { WrapperProps } from './WrapperProps';

export const DescriptionWrapper = styled.div<WrapperProps>`
    text-align: justify;
    ${fromLarge`
    ${({ titleLeft }) => `
      grid-column-start: ${titleLeft ? 2 : 1};
      grid-column-end: 3;
      grid-row-start: ${titleLeft ? 1 : 2};
      grid-row-end: ${titleLeft ? 2 : 3};
    `}
    ${({ titleLeft }) => !titleLeft && `padding-right: 358px; padding-left: 100px;`}
  `}
`;
