import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';
import { gridDisplay, gridTemplateColumns } from 'styleUtils/grid';
import { onIe } from 'styleUtils/browserSupport';

export const GridWrapper = styled.div`
    ${gridDisplay()}
    ${gridTemplateColumns('95px auto')}

    ${fromLarge`
        margin-right: 32px;
        ${onIe`
            padding-top: 32px;
        `}
    `}
`;
