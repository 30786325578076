import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';
import { gridRow, gridColumn } from 'styleUtils/grid';

export const ImageWrapper = styled.div`
    ${gridColumn({ start: 1 })}

    ${fromLarge`
        ${gridColumn({ start: 1 })}
        ${gridRow({ start: 1, end: 3 })}
        margin-right: 32px;
        margin-bottom: auto;
    `}
`;
