import React from 'react';

import { Wrapper } from './atoms/Wrapper';
import { Line } from './atoms/Line';

export const BreakerToPayoff1Line: React.FC = () => (
    <Wrapper>
        <Line src="/svg/ConnectionLines/BreakerToPayoff1.svg" />
    </Wrapper>
);
