import styled from 'styled-components';
import { fromLarge } from 'styleUtils/mediaQueries';
import { red, purple, white } from 'styles/colors';
import { werkenBij } from 'config/config.json';
import { gridDisplay } from 'styleUtils/grid';

const { itemHeight } = werkenBij.home.highlight;

interface WrapperProps {
    isPurple?: boolean;
    extraHeightInPixels: number;
}

export const Wrapper = styled.div<WrapperProps>`
    background-color: ${({ isPurple = false }): string => (isPurple ? purple : red)};
    color: ${white};
    padding: 1.5rem;
    ${gridDisplay()}
    align-items: center;
    position: relative;

    ${fromLarge`
        padding: 110px 70px;
        ${({ extraHeightInPixels: extraHeight }): string => ` height: calc(${itemHeight} + ${extraHeight}px);`}
  `};
`;
