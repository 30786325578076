import styled from 'styled-components';

export const Scroller = styled.div`
    width: 3px;
    height: 15px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;

    @keyframes scroll {
        0% {
            opacity: 0;
        }
        5% {
            opacity: 1;
        }
        20% {
            transform: translateY(0);
            opacity: 1;
        }
        60% {
            transform: translateY(10px);
            opacity: 0;
        }
        100% {
            transform: translateY(10px);
            opacity: 0;
        }
    }
`;
