import React from 'react';
import { FILE_BASE_URL } from 'variables';
import { Profile } from 'components/home/Profile';
import { HighlightDesign } from './types';
import { Payoff } from 'components/home/Payoff';
import { existy, unexisty } from 'utils/functional';
import { Highlight as HighlightProps } from 'types/highlight';
import { ProfileWrapper } from './atoms/ProfileWrapper';
import { HighlightWrapper } from './atoms/HighlightWrapper';
import { BottomImage } from './atoms/BottomImage';
import { PayoffWrapper } from './atoms/PayoffWrapper';
import { TopImage } from './atoms/TopImage';

export const Highlight: React.FC<HighlightProps> = ({
    payoff,
    topImage,
    bottomImage,
    payoffRight,
    hasOneImage,
    profile,
}) => {
    const getCurrentDesign = (): HighlightDesign => {
        if (unexisty(profile)) {
            return HighlightDesign.OnlyPayoff;
        }

        if (hasOneImage) {
            return HighlightDesign.OneImage;
        }

        return payoffRight ? HighlightDesign.PayoffRight : HighlightDesign.PayoffLeft;
    };

    const currentDesign: HighlightDesign = getCurrentDesign();

    return (
        <HighlightWrapper design={currentDesign}>
            {!hasOneImage && (
                <TopImage
                    design={currentDesign}
                    src={FILE_BASE_URL + topImage.webPPath}
                    fallback={FILE_BASE_URL + topImage.imagePath}
                />
            )}

            <PayoffWrapper design={currentDesign}>
                <Payoff isPurple={currentDesign === HighlightDesign.PayoffRight} {...payoff} />
            </PayoffWrapper>

            {existy(profile) && (
                <ProfileWrapper design={currentDesign}>
                    <Profile {...profile} />
                </ProfileWrapper>
            )}

            <BottomImage
                design={currentDesign}
                src={FILE_BASE_URL + bottomImage.webPPath}
                fallback={FILE_BASE_URL + bottomImage.imagePath}
            />
        </HighlightWrapper>
    );
};
