import styled from 'styled-components';
import { fromLarge, toLarge } from 'styleUtils/mediaQueries';

const avatarSmallSize = '75px';

export const PersonTop = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    p {
        margin: 0;
    }

    ${toLarge`
    .picture img {
      width: ${avatarSmallSize};
      max-width: ${avatarSmallSize};
      min-width: ${avatarSmallSize};
      height: ${avatarSmallSize};
      max-height: ${avatarSmallSize};
      min-height: ${avatarSmallSize};
    }
  `}

    ${fromLarge`
      align-items: center;
  `}
`;
