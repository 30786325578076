import styled from 'styled-components';
import { lightText } from 'styles/colors';
import { gridColumn, gridRow } from 'styleUtils/grid';

export const PersonDescriptionText = styled.div`
    text-align: justify;
    margin-top: 1.5rem;
    margin-bottom: 0;
    color: ${lightText};

    ${gridColumn({ start: 1 })}
    ${gridRow({ start: 2 })}
`;
