import styled from 'styled-components';
import { fromLarge, fromMedium } from 'styleUtils/mediaQueries';
import { DesignAware, HighlightDesign } from '../types';
import { gridColumn, gridRow } from 'styleUtils/grid';

export const ProfileWrapper = styled.div<DesignAware>`
    z-index: 1;

    ${fromMedium`
        ${({ design }): string => {
            switch (design) {
                case HighlightDesign.PayoffRight:
                    return `
                        ${gridColumn({ start: 2, end: 3 })}
                        ${gridRow({ start: 2 })}
                    `;
                case HighlightDesign.PayoffLeft:
                    return `
                        ${gridColumn({ start: 1, end: 2 })}
                        ${gridRow({ start: 2 })}
                    `;
            }
        }}
    `};

    ${fromLarge`
        ${({ design }): string => {
            switch (design) {
                case HighlightDesign.PayoffRight:
                    return `
                        ${gridColumn({ start: 1, end: 3 })}
                        ${gridRow({ start: 3, end: 5 })}
                    `;
                case HighlightDesign.PayoffLeft:
                    return `
                        ${gridColumn({ start: 3, end: 5 })}
                        ${gridRow({ start: 3, end: 5 })}
                    `;
                case HighlightDesign.OnlyPayoff:
                    return `
                        display: none;
                    `;
                case HighlightDesign.OneImage:
                    return `
                        ${gridColumn({ start: 3, end: 5 })}
                        ${gridRow({ start: 1, end: 3 })}
                    `;
                default:
                    break;
            }
        }}
    `};
`;
