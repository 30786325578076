import styled from 'styled-components';
import { fromLarge, fromMedium } from 'styleUtils/mediaQueries';
import { Image } from './Image';
import { HighlightDesign, ImageProps } from '../types';
import { gridColumn, gridRow } from 'styleUtils/grid';

export const TopImage = styled(Image)<ImageProps>`
    ${fromMedium`
        ${({ design }): string => {
            switch (design) {
                case HighlightDesign.PayoffRight:
                    return `
                        ${gridColumn({ start: 1, end: 2 })}
                        ${gridRow({ start: 1, end: 3 })}
                    `;
                case HighlightDesign.PayoffLeft:
                    return `
                        ${gridColumn({ start: 2, end: 3 })}
                        ${gridRow({ start: 1, end: 3 })}
                    `;
                default:
                    break;
            }
        }}
    `};

    ${fromLarge`
        
        ${gridColumn({ start: 2, end: 5 })}
        ${gridRow({ start: 1, end: 3 })}

        ${({ design }): string => {
            switch (design) {
                case HighlightDesign.PayoffRight:
                    return gridColumn({ start: 1, end: 4 });
                case HighlightDesign.OneImage:
                    return ` display: none; `;
                default:
                    break;
            }
        }}
    `};
`;
